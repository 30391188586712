import { createRouter, createWebHistory } from 'vue-router';
import HomePage from "@/components/HomePage.vue";
import GamePage from "@/components/GamePage.vue";
import ModelPage from "@/components/ModelPage.vue";
import NotePage from "@/components/NotePage.vue";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomePage
    },
    {
      path: '/my-games',
      name: 'myGames',
      component: GamePage
    },
    {
      path: '/my-models',
      name: 'myModels',
      component: ModelPage
    },
    {
      path: '/my-notes',
      name: 'myNotes',
      component: NotePage
    }
  ]
});
router.afterEach(() => {
  document.title = '个人日志'
})
export default router;
