<template>
  <div class="card-container">
    <div v-for="game in games" :key="game.id" class="card">
      <img :src="game.image" alt="Game Image" @click="openGame(game.url)">
      <p>{{ game.description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyGames',
  data() {
    return {
      games: [
        { id: 1, image: '/images/awula.png', description: '自裁吧，阿乌拉！', url: 'https://mingxuanmowang.top/awula/index.html' },
        { id: 2, image: 'https://gips2.baidu.com/it/u=3793389349,1404575344&fm=3039&app=3039&f=PNG?w=1024&h=1024', description: '这是游戏2', url: 'https://gips2.baidu.com/it/u=3793389349,1404575344&fm=3039&app=3039&f=PNG?w=1024&h=1024' }
      ]
    }
  },
  methods: {
    openGame(url) {
      window.open(url, '_blank')
    }
  }
}
</script>

<style>
/* 卡牌容器样式 */
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; /* 保证间距一致 */
  padding: 20px;
  max-width: 1200px; /* 控制最大宽度 */
  margin: auto; /* 居中显示 */
}

/* 单个卡牌样式 */
.card {
  background-color: #fff; /* 白色背景 */
  border: 1px solid #ddd; /* 边框 */
  box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* 阴影 */
  width: 300px; /* 卡牌宽度 */
  margin: 10px; /* 间隔 */
  transition: transform 0.3s ease-in-out; /* 动画效果 */
}

/* 图片样式 */
.card img {
  width: 100%; /* 图片宽度自适应卡牌宽度 */
  height: 200px; /* 图片高度 */
  object-fit: cover; /* 图片填充方式 */
}

/* 描述文本样式 */
.card p {
  padding: 10px;
  margin: 0;
  font-size: 16px; /* 文本大小 */
  color: #333; /* 文本颜色 */
}

/* 卡牌悬停效果 */
.card:hover {
  transform: scale(1.05); /* 悬停时放大 */
}
</style>

