<template>
  <div id="app">
    <nav>
      <router-link to="/" active-class="router-link-exact-active">主页</router-link>
      <router-link to="/my-games" active-class="router-link-exact-active">我的游戏</router-link>
      <router-link to="/my-models" active-class="router-link-exact-active">我的模型</router-link>
      <router-link to="/my-notes" active-class="router-link-exact-active">我的笔记</router-link>
    </nav>
    <router-view/>
  </div>
</template>
<script>

export default {
  name: 'App',
  components: {

  }
}
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

/* 导航栏整体样式 */
nav {
  background-color: #2c3e50; /* 深蓝色背景 */
  text-align: center;
  padding: 10px 0;
}

/* 链接样式 */
nav a {
  color: white; /* 白色字体 */
  text-decoration: none; /* 去除下划线 */
  padding: 12px 18px;
  font-size: 16px; /* 字体大小 */
  font-weight: 500; /* 字体粗细 */
  transition: background-color 0.3s; /* 过渡动画 */
}

/* 链接悬停效果 */
nav a:hover, nav a.router-link-exact-active {
  background-color: #3498db; /* 悬停时改变背景颜色 */
  border-radius: 5px; /* 圆角边框 */
}

/* 活动链接样式 */
.router-link-exact-active {
  border-bottom: 3px solid #f39c12; /* 底部边框高亮 */
}

</style>

